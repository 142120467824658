<template>
  <div class="column" id="newsLetterSignup">
    <h1>Newsletter Sign-up</h1>
    <p>Sign-up for the newsletter now to get the most up-to-date information on LEGO Universe.</p>
    <div id="newsLetterSignupButton">
      <router-link to="/newsletter" class="button"><span>Sign-up Now</span></router-link>
    </div>
  </div>
</template>

<style scoped lang="scss">
  #newsLetterSignup {
    width: 225px;
    height: 186px;
    background:
    transparent url('../assets/community/newsletter.png') top left no-repeat;
    position: relative;
  }

  #newsLetterSignup h1 {
    font-size: 1.7em;
    color:
    #000;
    position: absolute;
    top: 12px;
    left: 18px;
    width: 120px;
  }

  #newsLetterSignup p {
    position: absolute;
    color:
    #000;
    left: 18px;
    top: 90px;
    width: 195px;
    font-size: 1em;
    line-height: 1em;
  }

  #newsLetterSignup #newsLetterSignupButton {
    width: 183px;
    bottom: 12px;
    left: 12px;
    position: absolute;
  }

  #newsLetterSignup #newsLetterSignupButton .button {
    width: 100%;
  }
</style>
