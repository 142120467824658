
import CommunityMenu from "@/components/CommunityMenu.vue";
import NewsletterSignup from '@/components/NewsletterSignup.vue';

export default {
  data() {
    return {};
  },
  components: {
    CommunityMenu,
    NewsletterSignup,
  },
};
