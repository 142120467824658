<template>
  <div id="siteContainer">
    <div id="mainHeading">
      <div class="header">
        <h1><router-link to="/community">Community</router-link></h1>
        <breadcrumbs></breadcrumbs>
      </div>
    </div>

    <router-view id="mainColumn1"></router-view>

    <div id="mainColumn2">
      <div id="leftnavContent">
        <community-menu></community-menu>
        <div class="leftnav-row">
          <newsletter-signup></newsletter-signup>
        </div>
      </div>
    </div>
    <div class="clear"></div>
  </div>
</template>

<script lang="ts">
import CommunityMenu from "@/components/CommunityMenu.vue";
import NewsletterSignup from '@/components/NewsletterSignup.vue';

export default {
  data() {
    return {};
  },
  components: {
    CommunityMenu,
    NewsletterSignup,
  },
};
</script>
